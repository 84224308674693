import {ActivatedRoute, Router} from '@angular/router';
import { Component, OnInit } from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';

import { fadeIn } from 'src/app/animations/form-error';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../../interfaces/app-state';
import {HttpService} from '../../../../services/http/http.service';
import {UserService} from '../../../ui/administrateur/user.service';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../../../../services/sharedServices/authentication.service';
import {MatDialog} from '@angular/material';
import {ConfirmDialogComponent, ConfirmDialogModel} from '../../../ui/components/confirm-dialog/confirm-dialog.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'page-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss'],
  animations: [fadeIn]
})
export class PageSignOutComponent implements OnInit {
  loginForm: FormGroup;
  constructor( store: Store<IAppState>,
               private adminService: UserService,
               private toastr: ToastrService,
               public dialog: MatDialog,
               public router: Router

) { }

  ngOnInit() {
    this.loginForm = new FormGroup({
      mobilePhone: new FormControl('', [Validators.required])
     });
  }
  sedesabonner() {

      const message = 'Confirmez-vous la désactivation du compte, Attention l\\\'operation n\\\'est pas reversible ';

      const dialogData = new ConfirmDialogModel('Confirmation', message);

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          maxWidth: '500px',
          data: dialogData,
          panelClass: 'customDialog'
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
          if (dialogResult === true) {
              this.adminService.signOut(this.loginForm.get('mobilePhone').value).subscribe(
                  resultat => {
                      if (resultat.status === 'OK' ) {
                          this.toastr.success('Succès de la désactivation du compte ', '',
                              {timeOut: 2000});
                          this.router.navigate(['']);
                      } else {
                          this.toastr.error('Echec de la désactivation du compte ', '',
                              {timeOut: 2000});
                      }
                  }
              );
          }
      });


  }



}

