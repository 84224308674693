import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
// @Component({
//   // tslint:disable-next-line:component-selector
//   selector: 'app-root',
//   template: '<router-outlet></router-outlet>'
// })
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'angulartoastr';
  constructor(private toastr: ToastrService) {}
  showSuccess() {
    this.toastr.success('Hello world!', 'Toastr fun!',
        {timeOut: 2000});
  }
  showError() {
    this.toastr.error('everything is broken', 'Major Error', {
      timeOut: 3000
    });
  }
}
