import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ChartsModule } from 'ng2-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxEchartsModule } from 'ngx-echarts';
import { AgmCoreModule } from '@agm/core';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { FullCalendarModule } from '@fullcalendar/angular';
import { HighlightModule } from 'ngx-highlightjs';
import xml from 'highlight.js/lib/languages/xml';

export function hljsLanguages() {
  return [{ name: 'xml', func: xml }];
}
import { UIModule } from '../ui/ui.module';
import { LayoutModule } from '../layout/layout.module';
import { BasePageComponent } from './base-page';
import { PageAnalyticsComponent } from './dashboards/analytics';
import { Page404Component } from './errors/page-404';
import { Page500Component } from './errors/page-500';
import { Page503Component } from './errors/page-503';
import { Page505Component } from './errors/page-505';
import { PageSignInComponent } from './apps/authentication/sign-in';
import { PageECommerceComponent } from './dashboards/e-commerce';
import { PageDashboardComponent } from './dashboards/dashboard';
import { PageConnectionsComponent } from './apps/user-pages/connections';
import { ErrorBasePageComponent } from './errors/error-base-page';
import { PageGroupsComponent } from './apps/user-pages/groups';
import { PageCreateQualiteComponent } from './ui/qualite/create';
import { PageCreateMarcheComponent } from './ui/marche/create';
import { PageCreateCategorieComponent } from './ui/categorie/create';
import {PageCreateProduitComponent} from './ui/produit/create';
import {PageCreateMesureComponent} from './ui/mesure/create';
import {PageCreateActiviteComponent} from './ui/activite/create';
import {PageCreateGroupeComponent} from './ui/groupe/create';
import {PageCreateDepotComponent} from './ui/depot/create';
import {PageCreateRegionComponent} from './ui/region/create';
import {PageCreateProvinceComponent} from './ui/province/create';
import {PageCreateCommuneComponent} from './ui/commune/create';
import {
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule, MatDialogModule,
    MatFormFieldModule,
    MatGridListModule, MatIconModule,
    MatInputModule,
    MatListModule, MatPaginatorModule, MatProgressSpinnerModule,
    MatRadioModule, MatSortModule, MatStepperModule, MatTableModule, MatTabsModule, MatTooltipModule
} from '@angular/material';
import {NgZorroAntdModule} from 'ng-zorro-antd';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {PageCreateAdministrateurComponent} from './ui/administrateur/create';
import { SaisiePrixComponent } from './ui/saisie-prix/saisie-prix.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {PageCreateReseauComponent} from './ui/reseau/create';
import {TCMyPaginationComponent} from '../ui/components/myPagination';
import {PageIndexProduitComponent} from './ui/produit/index';
import {PageIndexUserComponent} from './ui/administrateur/index';
import {PageIndexMarcheComponent} from './ui/marche/index';
import {PageIndexCommuneComponent} from './ui/commune/index';
import {PageIndexActiviteComponent} from './ui/activite/index';
import {PageIndexSecteurActiviteComponent} from './ui/secteurActivite/index';
import {PageCreateSecteurActiviteComponent} from './ui/secteurActivite/create';
import {PageIndexDepotComponent} from './ui/depot/index';
import {PageIndexCategorieComponent} from './ui/categorie/index';
import {PageIndexGroupeComponent} from './ui/groupe/index';
import {PageIndexReseauComponent} from './ui/reseau/index';
import {PageIndexProvinceComponent} from './ui/province/index';
import {PageIndexRegionComponent} from './ui/region/index';
import {PageIndexMesureComponent} from './ui/mesure/index';
import {PageIndexQualiteComponent} from './ui/qualite/index';
import {SaisieStockComponent} from './ui/saisie-stock/saisie-stock.component';
import {SaisieOffreAchatComponent} from './ui/saisie-offre-achat/saisie-offre-achat.component';
import {NgxCurrencyModule} from 'ngx-currency';
import {PageProfilAdministrateurComponent} from './ui/profilAdmin';
import {SaisieOffreVenteComponent} from './ui/saisie-offre-vente/saisie-offre-vente.component';
import {ValiderPrixComponent} from './ui/valider-prix/valider-prix.component';
import {ValiderStockComponent} from './ui/valider-stock/valider-stock.component';
import {ValiderOffreAchatComponent} from './ui/valider-offre-achat/valider-offre-achat.component';
import {ValiderOffreVenteComponent} from './ui/valider-offre-vente/valider-offre-vente.component';
import {ListePrixValidesComponent} from './ui/liste-prix-valides/liste-prix-valides.component';
import {ListeOffresAchatsValidesComponent} from './ui/liste-offres_achats-valides/liste-offres-achats-valides.component';
import {PageMarketMapsComponent} from './ui/markets-maps';
import {ListeOffresVentesValidesComponent} from './ui/liste-offres_ventes-valides/liste-offres-ventes-valides.component';
import {PageGoogleMapsComponent} from './ui/maps/google-maps';
import {ConfirmDialogComponent} from './ui/components/confirm-dialog/confirm-dialog.component';
import {PageBanqueMondialeComponent} from './ui/partner-banque-mondiale';
import {PageBamigComponent} from './ui/partner-bamig';
import {PageSECODEVComponent} from './ui/partner-secodev';
import {ListeStockValidesComponent} from './ui/liste-stock-valides/liste-stock-valides.component';
import {PageDepotMapsComponent} from './ui/depots-maps';
import {PageCreateAlerteComponent} from './ui/alertePrix/create';
import {PageIndexAlerteComponent} from './ui/alertePrix/index';
import {ImporterProduitsComponent} from './ui/importer-produits/importer-produits.component';
import {FileUploadModule} from 'ng2-file-upload';
import {ImporterMarchesComponent} from './ui/importer-marches/importer-marches.component';
import {ImporterDepotsComponent} from './ui/importer-depots/importer-depots.component';
import {ImporterReseauxComponent} from './ui/importer-reseaux/importer-reseaux.component';
import {ImporterGroupesComponent} from './ui/importer-groupes/importer-groupes.component';
import {ImporterUtilisateursComponent} from './ui/importer-utilisateurs/importer-utilisateurs.component';
import {ImporterMesuresComponent} from './ui/importer-mesures/importer-mesures.component';
import {ImporterCategoriesComponent} from './ui/importer-categories/importer-categories.component';
import {ImporterEnqueteursComponent} from './ui/importer-enqueteurs/importer-enqueteurs.component';
import {ImporterAdministrateursComponent} from './ui/importer-administrateurs/importer-administrateurs.component';
import {ImporterSuperviseursComponent} from './ui/importer-superviseurs/importer-superviseurs.component';
import {PageIndexAlerteOffreVenteComponent} from './ui/alerteOffreVente/index';
import {PageCreateAlerteOffreVenteComponent} from './ui/alerteOffreVente/create';
import {PageIndexAlerteOffreAchatComponent} from './ui/alerteOffreAchat/index';
import {PageCreateAlerteOffreAchatComponent} from './ui/alerteOffreAchat/create';
import {PushSmsGroupeComponent} from './ui/push-sms/push-sms-groupe.component';
import {PushSmsMembresGroupesComponent} from './ui/push-sms-groupes/push-sms-membres-groupes.component';
import {PageCreateInfoComponent} from './ui/info/create';
import {PageIndexInfoComponent} from './ui/info/index';
import {ValiderInfoComponent} from './ui/valider-infos/valider-info.component';
import {DownloadAndroidAppComponent} from './ui/download-android-app/download-android-app.component';
import {ValiderNoteMarcheComponent} from './ui/valider-noteMarche/valider-noteMarche.component';
import {DownloadCodageSmsComponent} from './ui/download-codage-sms/download-codage-sms.component';
import {DownloadGuideComponent} from './ui/download-guide/download-guide.component';
import {Page506Component} from './errors/page-506';
import {EntityDataModule} from '@ngrx/data';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {entityConfig} from './ngrx-data/entitymetadata';
import {jqxListBoxModule} from 'jqwidgets-ng/jqxlistbox';
import {ImporterAnimateursComponent} from './ui/importer-animateurs/importer-animateurs.component';
import {ImporterEchProduitsComponent} from './ui/importer-echeantillons-produits/importer-echproduits.component';
import {jqxDateTimeInputModule} from 'jqwidgets-ng/jqxdatetimeinput';
import { ModifierSaisiePrixComponent } from './ui/modifier-saisie-prix/modifier-saisie-prix.component';
// tslint:disable-next-line:max-line-length
import {DownloadPolitiqueConfidentialiteComponent} from './ui/download-politique-confidentialite/download-politique-confidentialite.component';
import {SaisieEtalonnageComponent} from './ui/saisie-etalonnage/saisie-etalonnage.component';
import {PageSignUpComponent} from './apps/authentication/sign-up';
import { PageCreateCompteComponent } from '../layout/public/creerUnCompte';
import { MasterJqGridComponent } from './ui/master-jq-grid/master-jq-grid.component';
import {jqxGridModule} from 'jqwidgets-ng/jqxgrid';
import {PageIndexOffreAchatComponent} from './ui/saisie-offre-achat/index';
import {PageIndexOffreVenteComponent} from './ui/saisie-offre-vente/index';
import { ModifierSaisieStockComponent } from './ui/modifier-saisie-stock/modifier-saisie-stock.component';
import {ValiderEtalonnageComponent} from './ui/valider-etalonnages/valider-etalonnage.component';
import {ListeEtalonnageValidesComponent} from './ui/liste-etalonnage-valide/liste-etalonnage-valides.component';
import {PageCreateEspeceComponent} from './ui/espece/create';
import {PageIndexEspeceComponent} from './ui/espece/index';
import {PageCreateRaceComponent} from './ui/race/create';
import {PageIndexRaceComponent} from './ui/race/index';
import {ImporterRacesComponent} from './ui/importer-races/importer-races.component';
import {ImporterEspecesComponent} from './ui/importer-especes/importer-especes.component';
import {PageChangePasswordComponent} from './ui/changePasswd';
import {PageResetPasswdComponent} from './apps/authentication/reset';
import {ChronoAlerteComponent} from './ui/chronoAlerte';
import {PageResetLocalCacheComponent} from './ui/reinitialiserCache';
import {PageRapportsPrixComponent} from './dashboards/rapportsPrix';
import {jqxDataTableModule} from 'jqwidgets-ng/jqxdatatable';
import {jqxButtonModule} from 'jqwidgets-ng/jqxbuttons';
import {PageRapportsOffresVenteComponent} from './dashboards/rapportsOffresVente';
import { PageRapportsOffresAchatComponent } from './dashboards/rapportsOffresAchat';
import {PageRapportsSMSComponent} from './dashboards/rapportsSMS';
import { PageIndexAlerteStockComponent } from './ui/alerteStock/index';
import { PageCreateAlerteStockComponent } from './ui/alerteStock/create';
import { PageRapportsStockComponent } from './dashboards/rapportsStock';
import { PageMonitorAlerteComponent } from './dashboards/monitorAlertes';
import {ShowPasswordComponent} from './ui/showPasswd';
import {PageSignOutComponent} from './apps/authentication/sign-out';
import {HeaderFooterComponent} from '../my-header-footer/header-footer.component';
import {DonneesUtilisateursComponent} from '../donnees-utilisateurs/donnees-utilisateurs.component';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        ChartsModule,
        NgxChartsModule,
        BrowserAnimationsModule,
        NoopAnimationsModule,
        NgxEchartsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyAbIFQ5ffgouATqs-sp8hgQf3zV4dTLzaU',
        }),
        LeafletModule,
        HighlightModule.forRoot({languages: hljsLanguages}),
        FullCalendarModule,
        UIModule,
        LayoutModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        NgZorroAntdModule,
        NgxIntlTelInputModule,
        MatListModule,
        MatCardModule,
        MatRadioModule,
        MatGridListModule,
        MatTabsModule,
        FlexLayoutModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        MatSortModule,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        NgxCurrencyModule,
        FileUploadModule,
        MatStepperModule,
        StoreModule.forRoot({}),
        EffectsModule.forRoot([]),
        EntityDataModule.forRoot(entityConfig),
        jqxListBoxModule,
        jqxDateTimeInputModule,
        jqxGridModule,
        jqxDataTableModule,
        jqxButtonModule,
        MatDialogModule
    ],
    exports: [
        HeaderFooterComponent
    ],
    declarations: [
        BasePageComponent, ConfirmDialogComponent,
        PageAnalyticsComponent,
        PageSignInComponent,
        PageECommerceComponent,
        PageDashboardComponent,
        PageConnectionsComponent,
        ErrorBasePageComponent,
        PageGroupsComponent,
        PageCreateQualiteComponent,
        PageCreateMarcheComponent,
        PageIndexCategorieComponent,
        PageCreateCategorieComponent,
        PageCreateProduitComponent,
        PageCreateMesureComponent,
        PageCreateActiviteComponent,
        PageCreateGroupeComponent,
        PageCreateDepotComponent,
        PageCreateRegionComponent,
        PageIndexRegionComponent,
        PageCreateProvinceComponent,
        PageIndexProvinceComponent,
        PageCreateCommuneComponent,
        PageCreateAdministrateurComponent,
        PageCreateReseauComponent,
        PageIndexReseauComponent,
        PageIndexMarcheComponent,
        PageIndexCommuneComponent,
        TCMyPaginationComponent,
        SaisiePrixComponent,
        PageIndexProduitComponent,
        PageIndexUserComponent,
        PageIndexActiviteComponent,
        PageIndexSecteurActiviteComponent,
        PageCreateSecteurActiviteComponent,
        PageIndexDepotComponent,
        PageIndexMesureComponent,
        PageIndexGroupeComponent,
        PageIndexQualiteComponent,
        SaisieStockComponent,
        SaisieOffreAchatComponent,
        PageProfilAdministrateurComponent,
        SaisieOffreVenteComponent,
        ValiderPrixComponent,
        ValiderStockComponent,
        ValiderOffreAchatComponent,
        ValiderOffreVenteComponent,
        ListePrixValidesComponent,
        ListeOffresAchatsValidesComponent,
        ShowPasswordComponent,
        PageMarketMapsComponent,
        Page404Component,
        Page500Component,
        Page503Component,
        Page505Component,
        Page506Component,
        PageGoogleMapsComponent,
        ListeOffresVentesValidesComponent,
        PageBanqueMondialeComponent,
        PageBamigComponent,
        PageSECODEVComponent,
        ListeStockValidesComponent,
        PageDepotMapsComponent,
        PageIndexAlerteComponent,
        PageCreateAlerteComponent,
        ImporterProduitsComponent,
        ImporterMarchesComponent,
        ImporterDepotsComponent,
        ImporterReseauxComponent,
        ImporterGroupesComponent,
        ImporterUtilisateursComponent,
        ImporterMesuresComponent,
        ImporterCategoriesComponent,
        ImporterEnqueteursComponent,
        ImporterAdministrateursComponent,
        ImporterSuperviseursComponent,
        PageIndexAlerteOffreVenteComponent,
        PageCreateAlerteOffreVenteComponent,
        PageIndexAlerteOffreAchatComponent,
        PageCreateAlerteOffreAchatComponent,
        PushSmsGroupeComponent,
        PushSmsMembresGroupesComponent,
        PageCreateInfoComponent,
        PageIndexInfoComponent,
        ValiderInfoComponent,
        DownloadAndroidAppComponent,
        ValiderNoteMarcheComponent,
        DownloadCodageSmsComponent,
        DownloadGuideComponent,
        PageCreateEspeceComponent,
        PageIndexEspeceComponent,
        ImporterAnimateursComponent,
        ImporterEchProduitsComponent,
        ModifierSaisiePrixComponent,
        SaisieEtalonnageComponent,
        PageSignUpComponent,
        PageCreateCompteComponent,
        DownloadPolitiqueConfidentialiteComponent,
        MasterJqGridComponent,
        PageIndexOffreAchatComponent,
        PageIndexOffreVenteComponent,
        ModifierSaisieStockComponent,
        ValiderEtalonnageComponent,
        ListeEtalonnageValidesComponent,
        PageIndexRaceComponent,
        PageCreateRaceComponent,
        ImporterRacesComponent,
        ImporterEspecesComponent,
        PageChangePasswordComponent,
        PageResetPasswdComponent,
        ChronoAlerteComponent,
        PageResetLocalCacheComponent,
        PageRapportsPrixComponent,
        PageRapportsOffresVenteComponent,
        PageRapportsOffresAchatComponent,
        PageRapportsSMSComponent,
        PageIndexAlerteStockComponent,
        PageCreateAlerteStockComponent,
        PageRapportsStockComponent,
        PageSignOutComponent,
        DonneesUtilisateursComponent,
        PageMonitorAlerteComponent, HeaderFooterComponent

    ]
})
export class PagesModule { }
