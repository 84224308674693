<form [formGroup]="loginForm" (ngSubmit)="Login()">
    <div class="title-block">
        <h5 class="form-title">Connexion à la plateforme sima-bf</h5>
    </div>
    <div class="row">
        <div class="col-sm-7">
            <tc-form-group>
                <tc-input [prefixIcon]="'icofont-ui-user'" [placeholder]="'N° Mobile exple : +226xxxxxxxx'" formControlName="login"></tc-input>

                <ng-container *ngIf="login.invalid && (login.touched || login.dirty)">
                    <span @fadeIn class="error" *ngIf="login.getError('required')">Veuillez entrer votre identifiant</span>
                    <span @fadeIn class="error" *ngIf="login.getError('minlength')">L'identifiant doit avoir au moins 4 caractères</span>
                </ng-container>
            </tc-form-group>

            <tc-form-group>
                <tc-passwordinput [prefixIcon]="'icofont-lock'" [suffixIcon]="'icofont-eye'"  [placeholder]="'Mot de passe'" formControlName="password">
                </tc-passwordinput>
                <ng-container *ngIf="password.invalid && (password.touched || password.dirty)">
                    <span @fadeIn class="error" *ngIf="password.getError('required')">Veuillez entrer le mot de passe</span>
                    <span @fadeIn class="error" *ngIf="password.getError('minlength')">Le mot de passe doit avoir au moins 5 caractères</span>
                    <span @fadeIn class="error" *ngIf="password.getError('mustExist')">Mot de passe ou login incorrect</span>
                </ng-container>
            </tc-form-group>

            <button type="submit" tc-button [tcBgColor]="headerbgColor" [block]="true">Entrer</button>
        </div>
        <div class="col-sm-5 mycontent-left" >
            <div class="row myTopBox" >
                <a  [routerLink]="['/public/sign-up']"  class="myLink"> Je crée mon compte </a>
            </div>
            <div class="row myTopBox" >
                <a  [routerLink]="['/public/reset-pwd']" class="myLink" > Mot de passe oublié ? </a>
            </div>
            <div class="row myTopBox" >
                <a  [routerLink]="['/public/sign-out']" class="myLink" > Je me désabonne </a>
            </div>
        </div>
    </div>
</form>
