import { Routes } from '@angular/router';
import { VerticalLayoutComponent } from '../layout/vertical';
import { HorizontalLayoutComponent } from '../layout/horizontal';
import { PublicLayoutComponent } from '../layout/public';
import { ErrorLayoutComponent } from '../layout/error';
import { PageAnalyticsComponent } from '../pages/dashboards/analytics';
import { Page404Component } from '../pages/errors/page-404';
import { Page500Component } from '../pages/errors/page-500';
import { Page503Component } from '../pages/errors/page-503';
import { Page505Component } from '../pages/errors/page-505';
import { PageSignInComponent } from '../pages/apps/authentication/sign-in';
import { PageDashboardComponent } from '../pages/dashboards/dashboard';
import { PageECommerceComponent } from '../pages/dashboards/e-commerce';
import { PageCreateQualiteComponent } from '../pages/ui/qualite/create';
import { PageCreateMarcheComponent } from '../pages/ui/marche/create';
import { PageCreateCategorieComponent } from '../pages/ui/categorie/create';
import { PageCreateProduitComponent } from '../pages/ui/produit/create';
import { PageCreateMesureComponent } from '../pages/ui/mesure/create';
import { PageCreateActiviteComponent } from '../pages/ui/activite/create';
import { PageCreateGroupeComponent } from '../pages/ui/groupe/create';
import { PageCreateDepotComponent } from '../pages/ui/depot/create';
import { PageCreateRegionComponent } from '../pages/ui/region/create';
import { PageCreateProvinceComponent } from '../pages/ui/province/create';
import { PageCreateCommuneComponent } from '../pages/ui/commune/create';
import { WriterGuardService as WriterGuard } from '../services/sharedServices/writers-guard.service';
import { AuthGuardService as AuthGuard } from '../services/sharedServices/auth-guard.service';
import { AdminGuardService as AdminGuard } from '../services/sharedServices/admin-guard.service';
import { SupervisordGuardService as SupervGuard } from '../services/sharedServices/supervisors-guard.service';
import { PageCreateAdministrateurComponent } from '../pages/ui/administrateur/create';
import { SaisiePrixComponent } from '../pages/ui/saisie-prix/saisie-prix.component';
import { PageCreateReseauComponent } from '../pages/ui/reseau/create';
import { PageIndexProduitComponent } from '../pages/ui/produit/index';
import { PageIndexUserComponent } from '../pages/ui/administrateur/index';
import { PageIndexCommuneComponent } from '../pages/ui/commune/index';
import { PageIndexMarcheComponent } from '../pages/ui/marche/index';
import { PageIndexActiviteComponent } from '../pages/ui/activite/index';
import { PageCreateSecteurActiviteComponent } from '../pages/ui/secteurActivite/create';
import { PageIndexSecteurActiviteComponent } from '../pages/ui/secteurActivite/index';
import { PageIndexDepotComponent } from '../pages/ui/depot/index';
import { PageIndexCategorieComponent } from '../pages/ui/categorie/index';
import { PageIndexGroupeComponent } from '../pages/ui/groupe/index';
import { PageIndexReseauComponent } from '../pages/ui/reseau/index';
import { PageIndexProvinceComponent } from '../pages/ui/province/index';
import { PageIndexRegionComponent } from '../pages/ui/region/index';
import { PageIndexMesureComponent } from '../pages/ui/mesure/index';
import { PageIndexQualiteComponent } from '../pages/ui/qualite/index';
import { SaisieStockComponent } from '../pages/ui/saisie-stock/saisie-stock.component';
import { SaisieOffreAchatComponent } from '../pages/ui/saisie-offre-achat/saisie-offre-achat.component';
import { PageProfilAdministrateurComponent } from '../pages/ui/profilAdmin';
import { SaisieOffreVenteComponent } from '../pages/ui/saisie-offre-vente/saisie-offre-vente.component';
import { ValiderPrixComponent } from '../pages/ui/valider-prix/valider-prix.component';
import { ValiderStockComponent } from '../pages/ui/valider-stock/valider-stock.component';
import { ValiderOffreAchatComponent } from '../pages/ui/valider-offre-achat/valider-offre-achat.component';
import { ValiderOffreVenteComponent } from '../pages/ui/valider-offre-vente/valider-offre-vente.component';
import { ListePrixValidesComponent } from '../pages/ui/liste-prix-valides/liste-prix-valides.component';
import { ListeOffresAchatsValidesComponent } from '../pages/ui/liste-offres_achats-valides/liste-offres-achats-valides.component';
import { PageMarketMapsComponent } from '../pages/ui/markets-maps';
import { ListeOffresVentesValidesComponent } from '../pages/ui/liste-offres_ventes-valides/liste-offres-ventes-valides.component';
import { PageBanqueMondialeComponent } from '../pages/ui/partner-banque-mondiale';
import { PageBamigComponent } from '../pages/ui/partner-bamig';
import { PageSECODEVComponent } from '../pages/ui/partner-secodev';
import { ListeStockValidesComponent } from '../pages/ui/liste-stock-valides/liste-stock-valides.component';
import { PageDepotMapsComponent } from '../pages/ui/depots-maps';
import { PageIndexAlerteComponent } from '../pages/ui/alertePrix/index';
import { PageCreateAlerteComponent } from '../pages/ui/alertePrix/create';
import { ImporterProduitsComponent } from '../pages/ui/importer-produits/importer-produits.component';
import { ImporterMarchesComponent } from '../pages/ui/importer-marches/importer-marches.component';
import { ImporterDepotsComponent } from '../pages/ui/importer-depots/importer-depots.component';
import { ImporterReseauxComponent } from '../pages/ui/importer-reseaux/importer-reseaux.component';
import { ImporterGroupesComponent } from '../pages/ui/importer-groupes/importer-groupes.component';
import { ImporterUtilisateursComponent } from '../pages/ui/importer-utilisateurs/importer-utilisateurs.component';
import { ImporterMesuresComponent } from '../pages/ui/importer-mesures/importer-mesures.component';
import { ImporterCategoriesComponent } from '../pages/ui/importer-categories/importer-categories.component';
import { ImporterEnqueteursComponent } from '../pages/ui/importer-enqueteurs/importer-enqueteurs.component';
import { ImporterAdministrateursComponent } from '../pages/ui/importer-administrateurs/importer-administrateurs.component';
import { ImporterSuperviseursComponent } from '../pages/ui/importer-superviseurs/importer-superviseurs.component';
import { PageIndexAlerteOffreVenteComponent } from '../pages/ui/alerteOffreVente/index';
import { PageCreateAlerteOffreVenteComponent } from '../pages/ui/alerteOffreVente/create';
import { PageCreateAlerteOffreAchatComponent } from '../pages/ui/alerteOffreAchat/create';
import { PageIndexAlerteOffreAchatComponent } from '../pages/ui/alerteOffreAchat/index';
import { PushSmsGroupeComponent } from '../pages/ui/push-sms/push-sms-groupe.component';
import { PushSmsMembresGroupesComponent } from '../pages/ui/push-sms-groupes/push-sms-membres-groupes.component';
import { PageCreateInfoComponent } from '../pages/ui/info/create';
import { PageIndexInfoComponent } from '../pages/ui/info/index';
import { ValiderInfoComponent } from '../pages/ui/valider-infos/valider-info.component';
import { DownloadAndroidAppComponent } from '../pages/ui/download-android-app/download-android-app.component';
import { ValiderNoteMarcheComponent } from '../pages/ui/valider-noteMarche/valider-noteMarche.component';
import { DownloadCodageSmsComponent } from '../pages/ui/download-codage-sms/download-codage-sms.component';
import { DownloadGuideComponent } from '../pages/ui/download-guide/download-guide.component';
import { Page506Component } from '../pages/errors/page-506';
import { ImporterAnimateursComponent } from '../pages/ui/importer-animateurs/importer-animateurs.component';
import { ImporterEchProduitsComponent } from '../pages/ui/importer-echeantillons-produits/importer-echproduits.component';
import { ModifierSaisiePrixComponent } from '../pages/ui/modifier-saisie-prix/modifier-saisie-prix.component';
// tslint:disable-next-line:max-line-length
import { DownloadPolitiqueConfidentialiteComponent } from '../pages/ui/download-politique-confidentialite/download-politique-confidentialite.component';
import { SaisieEtalonnageComponent } from '../pages/ui/saisie-etalonnage/saisie-etalonnage.component';
import { PageCreateCompteComponent } from '../layout/public/creerUnCompte';
import { PageIndexOffreAchatComponent } from '../pages/ui/saisie-offre-achat/index';
import { PageIndexOffreVenteComponent } from '../pages/ui/saisie-offre-vente/index';
import { ModifierSaisieStockComponent } from '../pages/ui/modifier-saisie-stock/modifier-saisie-stock.component';
import { ValiderEtalonnageComponent } from '../pages/ui/valider-etalonnages/valider-etalonnage.component';
import { ListeEtalonnageValidesComponent } from '../pages/ui/liste-etalonnage-valide/liste-etalonnage-valides.component';
import { PageCreateEspeceComponent } from '../pages/ui/espece/create';
import { PageIndexEspeceComponent } from '../pages/ui/espece/index';
import { PageIndexRaceComponent } from '../pages/ui/race/index';
import { PageCreateRaceComponent } from '../pages/ui/race/create';
import { ImporterRacesComponent } from '../pages/ui/importer-races/importer-races.component';
import { ImporterEspecesComponent } from '../pages/ui/importer-especes/importer-especes.component';
import { PageChangePasswordComponent } from '../pages/ui/changePasswd';
import { PageResetPasswdComponent } from '../pages/apps/authentication/reset';
import { ChronoAlerteComponent } from '../pages/ui/chronoAlerte';
import { PageResetLocalCacheComponent } from '../pages/ui/reinitialiserCache';
import { PageRapportsPrixComponent } from '../pages/dashboards/rapportsPrix';
import { PageRapportsOffresVenteComponent } from '../pages/dashboards/rapportsOffresVente';
import { PageRapportsOffresAchatComponent } from '../pages/dashboards/rapportsOffresAchat';
import { PageRapportsSMSComponent } from '../pages/dashboards/rapportsSMS';
import { PageIndexAlerteStockComponent } from '../pages/ui/alerteStock/index';
import { PageCreateAlerteStockComponent } from '../pages/ui/alerteStock/create';
import { PageRapportsStockComponent } from '../pages/dashboards/rapportsStock';
import { PageMonitorAlerteComponent } from '../pages/dashboards/monitorAlertes';
import { ShowPasswordComponent } from '../pages/ui/showPasswd';
import { PageSignOutComponent } from '../pages/apps/authentication/sign-out';
import { DonneesUtilisateursComponent } from '../donnees-utilisateurs/donnees-utilisateurs.component';
var ɵ0 = [{ type: 'utilisateur' }], ɵ1 = [{ type: 'utilisateur' }], ɵ2 = [{ type: 'enqueteur' }], ɵ3 = [{ type: 'enqueteur' }], ɵ4 = [{ type: 'administrateur' }], ɵ5 = [{ type: 'animateur' }], ɵ6 = [{ type: 'administrateur' }], ɵ7 = [{ type: 'superviseur' }], ɵ8 = [{ type: 'superviseur' }], ɵ9 = [{ type: 'animateur' }], ɵ10 = [{ type: 'prix' }], ɵ11 = [{ type: 'stock' }], ɵ12 = [{ type: 'offre-achat' }], ɵ13 = [{ type: 'offre-vente' }];
var CHILD_ROUTES = [
    { path: 'dashboard', component: PageDashboardComponent, canActivate: [AuthGuard] },
    { path: 'analytics', component: PageAnalyticsComponent, canActivate: [AuthGuard] },
    { path: 'rapports-prix', component: PageRapportsPrixComponent, canActivate: [AdminGuard] },
    { path: 'rapports-offres-vente', component: PageRapportsOffresVenteComponent, canActivate: [AdminGuard] },
    { path: 'rapports-offres-achat', component: PageRapportsOffresAchatComponent, canActivate: [AdminGuard] },
    { path: 'rapports-stock', component: PageRapportsStockComponent, canActivate: [AdminGuard] },
    { path: 'rapports-journal-sms', component: PageRapportsSMSComponent, canActivate: [AdminGuard] },
    { path: 'monitoring-des-alertes', component: PageMonitorAlerteComponent, canActivate: [AdminGuard] },
    { path: 'e-commerce', component: PageECommerceComponent, canActivate: [AuthGuard] },
    { path: 'list-qualite', component: PageIndexQualiteComponent, canActivate: [AdminGuard] },
    { path: 'create-qualite', component: PageCreateQualiteComponent, canActivate: [AdminGuard] },
    { path: 'edit-qualite', component: PageCreateQualiteComponent, canActivate: [AdminGuard] },
    { path: 'list-marche', component: PageIndexMarcheComponent, canActivate: [AdminGuard] },
    { path: 'create-marche', component: PageCreateMarcheComponent, canActivate: [AdminGuard] },
    { path: 'edit-marche', component: PageCreateMarcheComponent, canActivate: [AdminGuard] },
    { path: 'list-categorie', component: PageIndexCategorieComponent, canActivate: [AdminGuard] },
    { path: 'create-categorie', component: PageCreateCategorieComponent, canActivate: [AdminGuard] },
    { path: 'list-produit', component: PageIndexProduitComponent, canActivate: [AdminGuard] },
    { path: 'create-produit', component: PageCreateProduitComponent, canActivate: [AdminGuard] },
    { path: 'list-mesure', component: PageIndexMesureComponent, canActivate: [AdminGuard] },
    { path: 'create-mesure', component: PageCreateMesureComponent, canActivate: [AdminGuard] },
    { path: 'edit-mesure', component: PageCreateMesureComponent, canActivate: [AdminGuard] },
    { path: 'list-activite', component: PageIndexActiviteComponent, canActivate: [AdminGuard] },
    { path: 'create-activite', component: PageCreateActiviteComponent, canActivate: [AdminGuard] },
    { path: 'edit-activite', component: PageCreateActiviteComponent, canActivate: [AdminGuard] },
    { path: 'list-groupe', component: PageIndexGroupeComponent, canActivate: [AdminGuard] },
    { path: 'create-groupe', component: PageCreateGroupeComponent, canActivate: [AdminGuard] },
    { path: 'list-depot', component: PageIndexDepotComponent, canActivate: [AdminGuard] },
    { path: 'create-depot', component: PageCreateDepotComponent, canActivate: [AdminGuard] },
    { path: 'edit-depot', component: PageCreateDepotComponent, canActivate: [AdminGuard] },
    { path: 'list-region', component: PageIndexRegionComponent, canActivate: [AdminGuard] },
    { path: 'create-region', component: PageCreateRegionComponent, canActivate: [AdminGuard] },
    { path: 'list-province', component: PageIndexProvinceComponent, canActivate: [AdminGuard] },
    { path: 'create-province', component: PageCreateProvinceComponent, canActivate: [AdminGuard] },
    { path: 'edit-province', component: PageCreateProvinceComponent, canActivate: [AdminGuard] },
    { path: 'list-commune', component: PageIndexCommuneComponent, canActivate: [AdminGuard] },
    { path: 'create-commune', component: PageCreateCommuneComponent, canActivate: [AdminGuard] },
    { path: 'edit-commune', component: PageCreateCommuneComponent, canActivate: [AdminGuard] },
    { path: 'list-utilisateur', component: PageIndexUserComponent, canActivate: [WriterGuard], data: ɵ0 },
    { path: 'modifier-les-offres-achats', component: PageIndexOffreAchatComponent, canActivate: [WriterGuard] },
    { path: 'modifier-les-offres-ventes', component: PageIndexOffreVenteComponent, canActivate: [WriterGuard] },
    { path: 'modifier-les-stocks', component: ModifierSaisieStockComponent, canActivate: [WriterGuard] },
    { path: 'create-utilisateur', component: PageCreateAdministrateurComponent, canActivate: [WriterGuard],
        data: ɵ1 },
    { path: 'list-enqueteur', component: PageIndexUserComponent, canActivate: [AdminGuard],
        data: ɵ2 },
    { path: 'create-enqueteur', component: PageCreateAdministrateurComponent, canActivate: [AdminGuard],
        data: ɵ3 },
    { path: 'list-administrateur', component: PageIndexUserComponent, canActivate: [AdminGuard],
        data: ɵ4 },
    { path: 'list-animateur', component: PageIndexUserComponent, canActivate: [AdminGuard],
        data: ɵ5 },
    { path: 'create-administrateur', component: PageCreateAdministrateurComponent, canActivate: [AdminGuard],
        data: ɵ6 },
    { path: 'list-superviseur', component: PageIndexUserComponent, canActivate: [AdminGuard],
        data: ɵ7 },
    { path: 'create-superviseur', component: PageCreateAdministrateurComponent, canActivate: [SupervGuard],
        data: ɵ8 },
    { path: 'create-animateur', component: PageCreateAdministrateurComponent, canActivate: [SupervGuard],
        data: ɵ9 },
    { path: 'create-reseau', component: PageCreateReseauComponent, canActivate: [SupervGuard] },
    { path: 'list-reseau', component: PageIndexReseauComponent, canActivate: [SupervGuard] },
    { path: 'create-espece', component: PageCreateEspeceComponent, canActivate: [AdminGuard] },
    { path: 'list-espece', component: PageIndexEspeceComponent, canActivate: [AdminGuard] },
    { path: 'create-race', component: PageCreateRaceComponent, canActivate: [AdminGuard] },
    { path: 'list-race', component: PageIndexRaceComponent, canActivate: [AdminGuard] },
    { path: 'create-secteur-activite', component: PageCreateSecteurActiviteComponent, canActivate: [AdminGuard] },
    { path: 'liste-secteur-activite', component: PageIndexSecteurActiviteComponent, canActivate: [AdminGuard] },
    { path: 'saisie-prix', component: SaisiePrixComponent, canActivate: [WriterGuard] },
    { path: 'modifier-les-prix', component: ModifierSaisiePrixComponent, canActivate: [WriterGuard] },
    { path: 'saisies-stocks', component: SaisieStockComponent, canActivate: [WriterGuard] },
    { path: 'saisies-offres-achats', component: SaisieOffreAchatComponent, canActivate: [WriterGuard] },
    { path: 'saisies-offres-ventes', component: SaisieOffreVenteComponent, canActivate: [WriterGuard] },
    { path: 'valider-les-prix', component: ValiderPrixComponent, canActivate: [AdminGuard] },
    { path: 'mon-compte', component: PageProfilAdministrateurComponent },
    { path: 'change-password', component: PageChangePasswordComponent },
    { path: 'reset-localCache', component: PageResetLocalCacheComponent },
    { path: 'show-password', component: ShowPasswordComponent, canActivate: [SupervGuard] },
    { path: 'valider-les-stocks', component: ValiderStockComponent, canActivate: [AdminGuard] },
    { path: 'valider-les-etalonnages', component: ValiderEtalonnageComponent, canActivate: [AdminGuard] },
    { path: 'valider-les-offres-achats', component: ValiderOffreAchatComponent, canActivate: [AdminGuard] },
    { path: 'valider-les-offres-vente', component: ValiderOffreVenteComponent, canActivate: [AdminGuard] },
    { path: 'liste-des-prix-valides', component: ListePrixValidesComponent, canActivate: [AdminGuard] },
    { path: 'liste-des-etalonnages-valides', component: ListeEtalonnageValidesComponent, canActivate: [AdminGuard] },
    { path: 'liste-des-offres-achats-valides', component: ListeOffresAchatsValidesComponent, canActivate: [AdminGuard] },
    { path: 'carte-des-marches', component: PageMarketMapsComponent },
    { path: 'carte-des-depots', component: PageDepotMapsComponent },
    { path: 'liste-des-offres-ventes-valides', component: ListeOffresVentesValidesComponent, canActivate: [AdminGuard] },
    { path: 'liste-des-stocks-valides', component: ListeStockValidesComponent, canActivate: [AdminGuard] },
    { path: 'liste-alertes-prix', component: PageIndexAlerteComponent, canActivate: [AdminGuard],
        data: ɵ10 },
    { path: 'liste-alertes-stock', component: PageIndexAlerteStockComponent, canActivate: [AdminGuard],
        data: ɵ11 },
    { path: 'create-alerte-stock', component: PageCreateAlerteStockComponent, canActivate: [AdminGuard] },
    { path: 'create-alerte-prix', component: PageCreateAlerteComponent, canActivate: [AdminGuard] },
    { path: 'create-alerte-offre-vente', component: PageCreateAlerteOffreVenteComponent, canActivate: [AdminGuard] },
    { path: 'create-alerte-offre-achat', component: PageCreateAlerteOffreAchatComponent, canActivate: [AdminGuard] },
    { path: 'liste-alertes-offre-achat', component: PageIndexAlerteOffreAchatComponent, canActivate: [AdminGuard],
        data: ɵ12 },
    { path: 'liste-alertes-offre-vente', component: PageIndexAlerteOffreVenteComponent, canActivate: [AdminGuard],
        data: ɵ13 },
    { path: 'chrono-alertes', component: ChronoAlerteComponent, canActivate: [AdminGuard] },
    { path: 'banque-mondiale', component: PageBanqueMondialeComponent },
    { path: 'secodev', component: PageSECODEVComponent },
    { path: 'bamig', component: PageBamigComponent },
    { path: 'saisie-etalonnage', component: SaisieEtalonnageComponent, canActivate: [AdminGuard] },
    { path: 'importer-produits', component: ImporterProduitsComponent, canActivate: [AdminGuard] },
    { path: 'importer-marches', component: ImporterMarchesComponent, canActivate: [AdminGuard] },
    { path: 'importer-depots', component: ImporterDepotsComponent, canActivate: [AdminGuard] },
    { path: 'importer-reseaux', component: ImporterReseauxComponent, canActivate: [SupervGuard] },
    { path: 'importer-groupes', component: ImporterGroupesComponent, canActivate: [AdminGuard] },
    { path: 'importer-utilisateurs', component: ImporterUtilisateursComponent, canActivate: [AdminGuard] },
    { path: 'importer-enqueteurs', component: ImporterEnqueteursComponent, canActivate: [AdminGuard] },
    { path: 'importer-animateurs', component: ImporterAnimateursComponent, canActivate: [AdminGuard] },
    { path: 'importer-administrateurs', component: ImporterAdministrateursComponent, canActivate: [SupervGuard] },
    { path: 'importer-superviseurs', component: ImporterSuperviseursComponent, canActivate: [SupervGuard] },
    { path: 'importer-mesures', component: ImporterMesuresComponent, canActivate: [AdminGuard] },
    { path: 'importer-categories', component: ImporterCategoriesComponent, canActivate: [AdminGuard] },
    { path: 'importer-races', component: ImporterRacesComponent, canActivate: [AdminGuard] },
    { path: 'importer-especes', component: ImporterEspecesComponent, canActivate: [AdminGuard] },
    { path: 'importer-echantillon', component: ImporterEchProduitsComponent, canActivate: [AdminGuard] },
    { path: 'envoyer-sms-groupe', component: PushSmsGroupeComponent, canActivate: [AdminGuard] },
    { path: 'envoyer-sms-membres', component: PushSmsMembresGroupesComponent, canActivate: [AdminGuard] },
    { path: 'liste-saisies-infos', component: PageIndexInfoComponent, canActivate: [SupervGuard] },
    { path: 'create-info', component: PageCreateInfoComponent, canActivate: [SupervGuard] },
    { path: 'valider-les-infos', component: ValiderInfoComponent, canActivate: [AdminGuard] },
    { path: 'valider-les-notes', component: ValiderNoteMarcheComponent, canActivate: [AdminGuard] },
    { path: 'download-android-app', component: DownloadAndroidAppComponent, canActivate: [WriterGuard] },
    { path: 'download-codage-sms', component: DownloadCodageSmsComponent, canActivate: [WriterGuard] },
    { path: 'download-guide', component: DownloadGuideComponent, canActivate: [WriterGuard] },
    { path: 'download-politique-confidentialite', component: DownloadPolitiqueConfidentialiteComponent },
];
var STATIC_ROUTES = [
    { path: 'regles_sur_donnees_utilisateur', component: DonneesUtilisateursComponent },
];
var PUBLIC_ROUTES = [
    { path: 'sign-in', component: PageSignInComponent },
    { path: 'sign-up', component: PageCreateCompteComponent },
    { path: 'reset-pwd', component: PageResetPasswdComponent },
    { path: 'sign-out', component: PageSignOutComponent },
    { path: '**', component: Page404Component }
];
var ERROR_ROUTES = [
    { path: '404', component: Page404Component },
    { path: '500', component: Page500Component },
    { path: '503', component: Page503Component },
    { path: '505', component: Page505Component },
    { path: '506', component: Page506Component },
    { path: '**', component: Page404Component }
];
export var ROUTES = [
    {
        path: '',
        redirectTo: '/vertical/dashboard',
        pathMatch: 'full'
    },
    {
        path: 'vertical',
        component: VerticalLayoutComponent,
        children: CHILD_ROUTES
    },
    {
        path: 'horizontal',
        component: HorizontalLayoutComponent,
        children: CHILD_ROUTES
    },
    {
        path: 'static',
        component: PublicLayoutComponent,
        children: STATIC_ROUTES
    },
    {
        path: 'public',
        component: PublicLayoutComponent,
        children: PUBLIC_ROUTES
    },
    {
        path: 'error',
        component: ErrorLayoutComponent,
        children: ERROR_ROUTES
    },
    {
        path: '**',
        component: ErrorLayoutComponent,
        children: ERROR_ROUTES
    },
    { path: 'regles_sur_donnees_utilisateur', component: DownloadPolitiqueConfidentialiteComponent }
];
var RoutingModule = /** @class */ (function () {
    function RoutingModule() {
    }
    return RoutingModule;
}());
export { RoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13 };
