/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header-footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./header-footer.component";
var styles_HeaderFooterComponent = [i0.styles];
var RenderType_HeaderFooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderFooterComponent, data: {} });
export { RenderType_HeaderFooterComponent as RenderType_HeaderFooterComponent };
export function View_HeaderFooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "a", [["routerLink", "/static/regles_sur_donnees_utilisateur"], ["style", "color: #FAB300"], ["target", "_blank"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { target: [0, "target"], routerLink: [1, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["R\u00E8gles sur les donn\u00E9es utilisateur"]))], function (_ck, _v) { var currVal_2 = "_blank"; var currVal_3 = "/static/regles_sur_donnees_utilisateur"; _ck(_v, 3, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_HeaderFooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header-footer", [], null, null, null, View_HeaderFooterComponent_0, RenderType_HeaderFooterComponent)), i1.ɵdid(1, 114688, null, 0, i4.HeaderFooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderFooterComponentNgFactory = i1.ɵccf("app-header-footer", i4.HeaderFooterComponent, View_HeaderFooterComponent_Host_0, {}, {}, []);
export { HeaderFooterComponentNgFactory as HeaderFooterComponentNgFactory };
