import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {enableProdMode, LOCALE_ID, NgModule} from '@angular/core';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { ROUTES, RoutingModule } from './routing/routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { PagesModule } from './pages/pages.module';
import { UIModule } from './ui/ui.module';
import { pageDataReducer } from './store/reducers/page-data.reducer';
import { appSettingsReducer } from './store/reducers/app-settings.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { ToastrModule } from 'ngx-toastr';
import {
  MatTableModule,
  MatDialogModule,
  MatDatepicker,
  MatDatepickerModule,
  MatNativeDateModule,
  MAT_DATE_LOCALE,
  MatInputModule,
  MatPaginatorModule,
  MatSortModule, MatProgressSpinnerModule, MatPaginatorIntl
} from '@angular/material';
import { ConfirmDialogComponent } from './pages/ui/components/confirm-dialog/confirm-dialog.component';
import { LoggingInterceptorService } from './services/logging-interceptor.service';
import {ExcelService} from './services/sharedServices/excel-service.service';
import { LoginComponent } from './login/login.component';
import {BasicAuthHtppInterceptorService} from './services/sharedServices/basic-auth-htpp-interceptor.service';
import {AuthGuardService} from './services/sharedServices/auth-guard.service';
import localeFr from '@angular/common/locales/fr';
import {DatePipe, registerLocaleData} from '@angular/common';
import {ErrorInterceptor} from './services/sharedServices/error-http-interceptor.service';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {FlexLayoutModule} from '@angular/flex-layout';
import {IconsProviderModule } from './icons-provider.module';
import {MatPaginatorIntlFr} from './pages/ui/customs/custom-paginator';
import {NgxCurrencyModule} from 'ngx-currency';
import {AdminGuardService} from './services/sharedServices/admin-guard.service';
import {WriterGuardService} from './services/sharedServices/writers-guard.service';
import {AngularWeatherWidgetModule} from 'angular2-weather-widget';
import {SupervisordGuardService} from './services/sharedServices/supervisors-guard.service';
import {DownloaderService} from './services/sharedServices/downloader.service';
import {MessageService} from './services/sharedServices/message.service';
import {FileUploadModule} from 'ng2-file-upload';
import { jqxDataTableModule } from 'jqwidgets-ng/jqxdatatable';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { jqxDateTimeInputModule } from 'jqwidgets-ng/jqxdatetimeinput';

registerLocaleData(localeFr);
export const httpInterceptorProviders = [
    {provide: HTTP_INTERCEPTORS, useClass: BasicAuthHtppInterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true}
];

export const customCurrencyMaskConfig = {
  align: 'right',
  allowNegative: true,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: ' ',
  nullable: true
};

@NgModule({
  declarations: [
	AppComponent,
	LoginComponent

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    LayoutModule,
    HttpClientModule, BrowserAnimationsModule,
    NoopAnimationsModule, MatDatepickerModule, MatNativeDateModule, MatDialogModule,
	MatTableModule, MatDatepickerModule, FlexLayoutModule,
    MatInputModule,
    MatPaginatorModule,
    MatSortModule, FileUploadModule,
    MatProgressSpinnerModule, AngularWeatherWidgetModule
    ,
    RouterModule.forRoot(ROUTES, { useHash: true}),
    StoreModule.forRoot({
      pageData: pageDataReducer,
      appSettings: appSettingsReducer,
    }),
    RoutingModule,
    PagesModule,
	UIModule,
    NgxIntlTelInputModule,
	ToastrModule.forRoot(), // ToastrModule added
    jqxDataTableModule, jqxButtonModule, jqxDateTimeInputModule,
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }), IconsProviderModule
  ],
   entryComponents: [
    ConfirmDialogComponent
  ],
  providers: [httpInterceptorProviders, ExcelService, DownloaderService, MessageService,
    AuthGuardService, AdminGuardService, SupervisordGuardService,
    WriterGuardService,
   {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
     {provide: MatPaginatorIntl, useClass: MatPaginatorIntlFr},
      MatDatepickerModule, MatNativeDateModule, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule {
  nomOrganisation: string;
  constructor() {

  }

}
