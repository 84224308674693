import { Component, OnInit } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-donnees-utilisateurs',
  templateUrl: './donnees-utilisateurs.component.html',
  styleUrls: ['./donnees-utilisateurs.component.scss']
})
export class DonneesUtilisateursComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
