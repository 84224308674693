<ng-container *ngIf="canLoadFooter">
<div  class="footer-wrap footerarea" >
  <div  class="row">
    <div class="col-sm-3" style="padding-top: 0; ">
        <div  class="row">
              <partners-logo
                      [src]="'assets/img/BM90X60.png'"
                      [page]="'/vertical/banque-mondiale'"
                      [title]="'BANQUE MONDIALE : Partenaire Financier'"
                      [width]="90"
                      [height]="60"

              ></partners-logo>

            <partners-logo
                    [src]="'assets/img/bamig90x60.png'"
                    [title]="'BAMIG : Partenaire Technique (Conception et Développement)'"
                    [page]="'/vertical/bamig'"
                    [width]="90"
                    [height]="60"
            ></partners-logo>
        </div>
    </div>
    <div class="col-sm-9 ">
      <div class="row footeraddr" style="padding-top:10px">
        <div class="col-sm-3 ">
          <address>
            {{nomOrganisation}}<br>
          </address>
        </div>
        <div class="col-sm-6 ">

           <strong> Contact : </strong>
          <div style="display: block">
            Contact : {{mobile}}
          </div>
        </div>
        <div class="col-sm-3 ">
          <address>
            Email :<br>
            <a href="mailto:{{email}}">{{email}}</a>

          </address>
        </div>


      </div>
    </div>

  </div>
</div>
</ng-container>
